import { mapHelper } from "@/utils/common.js";
const timeDuring = [
  {
    value: 1,
    label: "上午",
  },
  {
    value: 2,
    label: "下午",
  },
];
const { map: timeDuringMap, setOps: setTimeDuringOps } =
  mapHelper.setMap(timeDuring);

const classStatus = [
  {
    value: 1,
    label: "已结束",
  },
  {
    value: 2,
    label: "进行中",
  },
  {
    value: 3,
    label: "待开始",
  },
];
const { map: classStatusMap, setOps: setClassStatusOps } =
  mapHelper.setMap(classStatus);

export { timeDuringMap, setTimeDuringOps, classStatusMap, setClassStatusOps };
